@import "sass-lib";
@media (min-width: 0px) and (max-width: 1480px) {
	.btn{
		&.export{
			height: 40px; line-height: 40px; padding: 0px 20px 0 30px;
			&:before{ width:12px; background-size: 100%; top: 5px; left: 9%;	}
		}
		&.login{ 
			height: 40px; line-height: 40px; padding: 0px 0px 0px 35px; width: 80px; margin: 0 5px;
			&:before{ width:15px; background-size: 100%; }
		}
	}
	header{
		height: 65px; 
		&.no-login {
			&:before {width: 22%;}
		}
		&:before{ width:28%; height:60px;  @include borderRadius(0px 0px 0px 50px); }
		&.float{ 
			height: 60px; 
			&:before{
				height:60px;
			}
			.logo{ padding-top: 5px; }
			.right-header{ padding-top: 10px; }
		}
		.wrapper{ margin: 0px 70px 0px 100px; }
		.logo{ width: 140px; }
		.nav-burgermenu{
			.in-nav-burgermenu{
				padding: 30px 0px 15px; overflow-y:auto;
				.mCSB_inside > .mCSB_container{ margin-right: 0; }
				.menu{
					> li{ 
						padding:10px 40px; @include boxSizing(border-box);  font-size: 18px; margin-bottom: 5px;
						.drop-burger{
							> ul{
								padding: 30px 0 15px;
								li{ 
									font-size: 14px; margin-bottom: 10px; padding: 10px 40px; 
									&:before{ left:5px; }
									&:hover{
										&:before{ left:5px; }
									}
									.sub-drop-burger{ padding: 30px 0px 15px; }
								}
							}
						}
					}
				}
			}
			.roundedlink{ 
				margin-bottom: 20px;
				a{ 
					font-size: 12px; height: 35px; line-height: 35px; padding: 0px 30px 0px 45px;
					&.tosite{
						&:before{ width:15px; background-size: 100%; }
					}
					&.log{
						&:before{ width:15px; background-size: 100%; }
					}
				}
			}
			.searchbox{
				margin-bottom: 20px;
				input[type=text]{ height: 50px; }
			}
			.close-nav{ top: 25px; right: 40px;}
			.bottom-head{ padding: 0px 40px; }
		}
		.right-header{ 
			padding-top: 10px; 
			a{ font-size: 12px;   }
			.burgermenu{ 
				font-size: 7px; margin-top: 5px;
				.togglemenu{ 
					width: 20px; height: 20px; 
					span{ 
						height: 2px; 
						&:nth-of-type(2){top: 5px;}
						&:nth-of-type(3){top: 10px;}
					}
				}
			}
		}
	}
	.banner-home{
		height: calc(100vh - 90px); 
		.text{ 
			width: 525px; top: 35%; @include transform(translateY(-35%)); left: 6%;
			.btn.gradient{ margin-top: 10px; }
			h2{ font-size: 32px; line-height: 32px; }

		}
		.tabing{
			 left: 5%;
			ol{
				li{ 
					margin: 0 15px; width:150px;
					a{ font-size: 12px; }
				}
			}
		}
		.slider-small{
			width: 150px;
			.list-slider{ width: 120px; margin-right: 5px; }
		}
	}
	.ttl-content{
		padding-bottom: 20px; margin-bottom: 25px;
		h5{ font-size: 14px; }
		&:after{ width:80px; left: -30px;  }
	}
	.tittle-middle h2{ font-size: 28px; line-height: 38px; }
	.home{
		padding: 60px 0px;
		h2{ font-size: 28px; line-height: 38px; }
		h3{ font-size: 22px; line-height: 32px; }
		&.calculator{
			padding: 15px 0px 0px;
			.wrapper{ margin: 0px 20px 0 160px;}
			&:before{  }
			h4{ font-size: 18px; line-height: 28px; }
			.calculator-right{
				@include afterclear; position: relative;
				a{ position: absolute; right: 0; top: 50%; @include transform(translateY(-50%)); width: 200px; font-size: 14px; display: block; padding: 0px 20px; line-height: 55px; text-align: center;}
				h4{ padding: 0px 200px 0px 20px;  }
			}
		}
		&.calculator{
			 height:90px; padding: 10px 0px 0px;
			 h4{ font-size: 14px; line-height: 22px; }
			 .wrapper{ margin: 0px 20px 0 100px; }
			&:before{ background-position: left; width: 30%; }
			 .calculator-left{
			 	@include afterclear;
			 	h3{ float: left; width: 20%; font-size: 20px; line-height: 25px; padding-top: 5px; margin-bottom: 0px; }
			 	.form-calculator{ float: right; width: 80%; }
			 }
			 .calculator-right{
			 	a{
			 		line-height: 45px; width: 180px; font-size: 12px;
			 	}
			 }
			 .top-form-calculator{
			 	padding: 0px 0px 5px 25px;
			 	label{ font-size: 11px; margin-right: 65px;}
			 }
			 .bottom-form-calculator{
			 	height: 40px;
			 	input[type=number],select{ width: 120px; height: 40px;  font-size: 12px; padding: 0px 20px 0px 5px;}
			 	input[type='text']{ width: 170px; height: 40px; font-size: 12px; padding-left: 5px;}
			 	select{ background-size: 7%; width: 150px; }
			 	button{
			 		top: 5px; bottom: 5px; right: 4px; width: 120px; height: 30px; font-size: 11px;
			 		&:before{ width:11px; background-size: 100%; left: 15%; }
			 		
			 	}
			 }
		}
		&.services{
			.services-list{
				.ls-services{
					.desc{
						&:before{ width:500px; height: 350px; }
						h5{ font-size: 18px; line-height: 24px; }
					}
				}
			}
		}
	}
	.calculator-foot{
		.lf-calculator{
			h3{ font-size: 20px; }
			.btn-simulasi{
				&:after{ width:20px; background-size: 100%; right: 50%; }
			}

		}
		.btn{ padding: 0 20px; text-align: center; font-size: 12px; }
	}
	.bottom-form-calculator{
		height: 50px;
		input[type='text'], input[type=number], select{ font-size: 14px; height: 50px; }
		button{ top:5px; bottom: 5px; right: 5px;  }
	}
	.form-calculator{
		> div {
			margin-right: 10px;
			&:after {right: -5px;}
		}
		&.fixed{
			select{ width: 160px; padding-right: 35px; background-position: right 10px center;}
			div:nth-child(2) select {width: auto;}
		}
	}
	.middle{
		aside{
			&.fixed{ left: 6.5%; }
			&.botzero{left: 6.5%;}
		}
	}
	
	.banner-middle{
		figure{ height: 370px; }
	}
	.banner-home{
		
	}
	.share-medsos{
		width: 25px;
		a{ width: 100%; height: 25px; }
	}
	.page100vh{
		.wrap{
			.text{ 
				left: 20%; right: 20%; 
			}
		}
	}
	.middle{
		aside{
			.navigasi{ min-height: 300px; max-height: 480px; padding:120px 0px 50px 30px;  }
		}
	}
	.milestone-slider{
		.list{
			padding: 60px 0px;
			&.slick-current{
				figure{ @include transform(scale(1.2)); }
			}
			.desc{ 
				top: 20px; 
				h5{ font-size: 28px; }
			}
		}
	}
	.detail-milestone{ margin-top: 10px; }
	.table-middle.corpo {
		table {min-width: 900px;}
	}
}